.cardContainer {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 1rem;
  margin-bottom: var(--size-10);
}

.thumbnail {
  margin-top: var(--size-10);
  border: var(--clr-accent-1) 2px solid;
  border-radius: 7px;
  transition: all .4s ease-in-out;
  opacity: 0.9;
  box-shadow: var(--neon-near-shadow);
}

.thumbnail:hover {
  box-shadow: var(--neon-near-shadow), var(--neon-mid-shadow);
}

.cardDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-weight: normal;
  font-size: var(--font-size-project-card-title);
}

.tagsList {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  padding-bottom: 1rem;
}

.tags {
  border: var(--clr-gray-2) 3px solid;
  color: var(--clr-gray-2);
  border-radius: 5px;
  padding: .3rem;
  flex-shrink: 0;
  transition: all .3s;
}

.tags:hover {
  background-color: var(--clr-gray-1);
  color: var(--clr-gray-8);
}

.description {
  color: var(--clr-gray-2);
  font-size: var(--font-size-details);
  padding-bottom: var(--size-5);
}

.linkList {
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.githubIcon {
  box-sizing: border-box;
  width: 4.5rem;
  padding: .7rem;
  /* Cambio el color del svg */
  filter: invert(85%) sepia(8%) saturate(215%) hue-rotate(212deg) brightness(92%) contrast(89%);
}

@media (min-width: 800px) {
  .cardContainer {
    padding: 0 15%;
  }
}

@media (min-width: 1300px) {
  .thumbnailContainer, .cardDetails {
    width: 50%;
  }

  .cardContainer {
    padding: 0 var(--size-13);
    flex-direction: row;
    align-items: center;
    gap: var(--size-10);
    margin-bottom: var(--size-13);
  }

  .title {
    font-size: var(--font-size-project-card-title);
    padding-bottom: var(--size-4);
  }

  .description {
    font-size: var(--font-size-details);
  }

  .reverse {
    order: 2;
  }
}
