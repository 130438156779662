.pageContainer {
  overflow: hidden;
}

.header {
  text-align: center;
  margin-bottom: var(--size-4);
}

.title {
  font-size: var(--font-size-title);
}

.subtitle {
  font-size: var(--font-size-subtitle);
}

@media (min-width:1300px) {
  .pageContainer {
    padding-top: var(--size-10);
  }

  .header {
    margin-bottom: var(--size-13);
  }
}