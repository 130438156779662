:root {
  --clr-gray-8: #18191A;
  --clr-gray-7: #272822;
  --clr-gray-6: #35362f;
  --clr-gray-5: #838181;
  --clr-gray-4: #9e9d9d;
  --clr-gray-3: #b4b4b4;
  --clr-gray-2: #c4c2cc;
  --clr-gray-1: #F5F6F7;

  --clr-accent-3: #9e113d;
  --clr-accent-2: #aa093c;
  --clr-accent-1: #f3004b;

  --clr-background: var(--clr-gray-8);

  --font-size-title: 3rem;
  --font-size-project-card-title: 3rem;
  --font-size-subtitle: 1.5rem;
  --font-size-details: 1.5rem;


  --font-size-navbar: 1.5rem;
  --padding-small: 1rem;
  --padding-big: 8rem;
  --padding-medium: 4rem;


  --size-1: .25rem;
  --size-2: .50rem;
  --size-3: .75rem;
  --size-4: 1rem;
  --size-5: 1.5rem;
  --size-6: 2rem;
  --size-7: 2.5rem;
  --size-8: 3rem;
  --size-9: 3.5rem;
  --size-10: 4rem;
  --size-11: 4.5rem;
  --size-12: 6rem;
  --size-13: 8rem;

  --neon-near-shadow:     
    0 0 5px var(--clr-accent-1),
    0 0 10px var(--clr-accent-1),
    0 0 20px var(--clr-accent-1);

  --neon-mid-shadow: 
    0 0 40px var(--clr-accent-1);

  --neon-far-shadow:
    0 0 80px var(--clr-accent-1),
    0 0 100px var(--clr-accent-2),
    0 0 150px var(--clr-accent-2);
}

@media (min-width: 500px) {
  :root {
    --font-size-title: 4rem;
    --font-size-project-card-title: 3.5rem;
    --font-size-subtitle: 1.5rem;
    --font-size-details: 1.1rem;
  }
}

@media (min-width: 800px) {
  :root {
  --font-size-title: 5rem;
  --font-size-project-card-title: 4rem;
  --font-size-subtitle: 3rem;
  --font-size-details: 1.1rem;
  }
}

@media (min-width: 1300px) {
  :root {
  --font-size-title: 7rem;
  --font-size-project-card-title: 4rem;
  --font-size-subtitle: 3rem;
  --font-size-details: 1.3rem;
  }
}

@media (min-width: 1600px) {
  :root {
  --font-size-title: 7rem;
  --font-size-project-card-title: 5rem;
  --font-size-subtitle: 3rem;
  --font-size-details: 1.5rem;
  }
}

body {
  background: var(--clr-background);
  color: var(--clr-gray-1);
  font-family: 'Poppins', sans-serif;
}

.accent {
  color: var(--clr-accent-1);
  font-style: normal;
}

img {
  max-width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

/* Mantiene el footer abajo de todo. */

.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.router {
  display: flex;
  flex-direction: column;
}

.neon {
  color: var(--clr-gray-1);
  text-shadow: var(--neon-near-shadow), var(--neon-mid-shadow), var(--neon-far-shadow);
}

.light-neon {
  color: var(--clr-gray-1);
  text-shadow: var(--neon-near-shadow);
}