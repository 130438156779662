.pageContainer {
  overflow: hidden;
}

.header {
  text-align: center;
  margin-bottom: var(--size-4);
}

.title {
  font-size: var(--font-size-title);
}

.text {
  padding: 0 var(--size-4) var(--size-10) var(--size-4);
  font-size: var(--font-size-details);
  color: var(--clr-gray-2);
}

.paragraph {
  margin-bottom: var(--size-3);
}

@media (min-width: 800px) {
  .pageContainer {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }

  .header {
    grid-column: 2;
  }

  .text {
    padding: 0;
    grid-column: 2;
  }
}

@media (min-width: 1300px) {
  .pageContainer {
    grid-template-columns: 1fr 3fr 1fr;
    padding: var(--size-10) 0;
  }
  
  .header {
    margin-bottom: var(--size-10);
  }  
}