.navbarContainer {
  position: sticky;
  padding: 1rem 0;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2rem;
  z-index: 2;
  background-color: var(--clr-gray-8);
  box-shadow: 0 .5rem rgba(0,0,0,.1);
  opacity: 0.9;
  transition: all .375s;
}

.navbarContainer.open {
  opacity: 1;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.item{
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: var(--clr-gray-1);
  position: relative;
  font-size: var(--font-size-navbar);
}

:global .active {
  color: var(--clr-accent-1)
}

/* Animacion navbar item */
.item a::before {
  background-color: var(--clr-accent-1);
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 3px;
  transition: all .35s ease-in-out;
}

.item a:hover::before {
  width: 100%;
}

/* Boton burguer navbar */

.openBtn {
  display: none;
  cursor: pointer;
  padding: .2rem;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: var(--clr-gray-1);
  margin: 6px 0;
  transition: all 0.6s ease-in-out;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
  transform: rotate(-45deg) translate(-9px, 6px) ;
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
  transform: rotate(45deg) translate(-8px, -8px) ;
}


/* Animacion para mostrar navbar en telefono. */
@media (max-width: 800px) {
  .openBtn {
    display: inline-block;
  }

  .listContainer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
  }

  .listContainer.open {
    opacity: 1;
    visibility: visible;
  }

  .listContainer::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    box-shadow: 3px 0 var(--clr-accent-1);
    /* El margin evita que la navbar quede incompleta al abrir y cerrar la barra de navegacion del browser. */
    margin-bottom: -4rem;
    background: var(--clr-gray-7);
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .275s .1s;
  }

  .listContainer.open::before {
  transform: skew(-14deg) translateX(0);
  }

  .list {
    display: inline-flex;
    flex-direction: column;
    height: 100%; 
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
  }

  .item {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
  }
}