.accentBtn {
  background-color: var(--clr-accent-1);
  color: var(--clr-gray-1);
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  transform:  
    rotate(-5deg)
    skew(-8deg);
  cursor: pointer;
  transition: all 0.3s ease-in-out; 
}

.accentBtn:hover, .accentBtn:active, .accentBtn:focus {
  box-shadow: 6px 6px 0 var(--clr-accent-2);
  transform: 
    translate(-10px, -10px)
    skew(-8deg)
    rotate(-5deg);
}

@media (min-width: 800px) {
  .accentBtn {
    padding: 1rem;
  }
}

@media (min-width: 1300px) {
  .accentBtn {
    font-size: 1.5rem;
  }
}