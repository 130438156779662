.container {
  padding: var(--size-4) 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.text {
  text-align: center;
  font-size: var(--desktop-font-size-project-card-details);
  padding-bottom: var(--size-4);
}

.svgBorder {
  border-radius: 50%;
  border: 3px solid var(--clr-gray-1);
  transition: all .3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.svgBorder:hover {
  border-color: var(--clr-accent-1);
}

.svgIcon {
  padding: 0.6rem;
  width: 3rem;
  filter: invert(90%) sepia(3%) saturate(1203%) hue-rotate(320deg) brightness(109%) contrast(90%);
  box-sizing: border-box;
}

