.pageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--size-8);
  overflow: hidden;
  flex-grow: 1;
}

.neonContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 1200px;
  height: 600px;
}

.textContainer {
  display: flex;
  flex-flow: column;
  font-size: var(--size-5);
  line-height: 1.2;
  align-items: flex-start;
  margin: 10px;
}

.title {
  font-size: var(--size-8);
  font-weight: 600;
}

.text {
  margin-left: var(--size-5);
  margin-bottom: var(--size-6);
}

.buttonContainer {
  width: fit-content;
}

.neonBorder {
  position: absolute;
  transform: scale(.3) rotate(45deg);
}

.topNeonBorder {
  top: 0;
  left: 50%;
  transform-origin: top left;
}

.bottomNeonBorder {
  bottom: 0;
  right: 50%;
  transform-origin: bottom right;
}

@media (min-width: 550px) {
  .textContainer {
    font-size: var(--size-7);
  }
  .title {
    font-size: var(--size-10);
  }
  .neonBorder {
    transform: scale(.35);
  }
  .topNeonBorder {
    left: 0;
  }
  .bottomNeonBorder {
    right: 0;
  }
}

@media (min-width: 1300px) {
  .pageContainer {
    padding: 0;
    min-height: 90vh;
  }
  .textContainer {
    font-size: var(--size-7);
  }
  .title {
    font-size: var(--size-10);
  }
}